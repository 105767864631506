import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
const WSTest = () => {
  const client = new W3CWebSocket(
    "ws://ocpp.wemaker.space:8080/cs/websocket/CentralSystemService/ABX001"
  );

  client.onopen = () => {
    console.log("WebSocket Client Connected");
  };

  client.onmessage = (message) => {
    console.log(message);
  };
  client.onerror = function () {
    console.log("Connection Error");
  };
  return <div>sdfsd</div>;
};

export default WSTest;
