import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Charger } from "../../../../interfaces";
import SearchChargerListTile from "../SearchChargerListTile/SearchChargerListTile";

interface Props {
  chargers: Charger[];
  currentLocation: GeolocationPosition;
}

const SearchChargerList = (props: Props) => {
  const { chargers, currentLocation } = props;
  // Working with W3C Geolocation API

  return (
    <Grid container justifyContent={"center"}>
      {chargers.length > 0 &&
        chargers.map((charger) => (
          <Grid item xs={12} md={8} key={charger.cID}>
            <SearchChargerListTile
              key={charger.cID}
              charger={charger}
              currentLocation={currentLocation}
            />

            <Box sx={{ width: "90%", margin: "1rem" }} />
          </Grid>
        ))}
    </Grid>
  );
};
export default SearchChargerList;
