import dayjs from "dayjs";
import BILLING_STATUS from "../constants/BILLING_STATUS";
import BOOKING_STATUS from "../constants/BOOKING_STATUS";
import IMAGES from "../constants/IMAGES";
import {
  Billing,
  Booking,
  ChargerType,
  Notification,
  User,
  UserOtherDetails,
} from "../interfaces";
import { MyVehicle } from "../interfaces/Vehicle";
import theme from "../utils/theme";

export const mockFetching = (url: string) =>
  new Promise((resolve) => setTimeout(resolve, 2000));

export const mockUser: User = {
  fullName: "Jane Doe",
  email: "janedoe@gmail.com",
  phoneNo: "+060123456789",
  profileUrl: IMAGES.MOCK_PROFILE_PICTURE,
};

export const mockNotifications: Notification[] = [
  {
    icon: {
      name: "event_available",
      iconColor: theme.palette.success.main,
      backgroundColor: theme.palette.success.light,
    },
    title: "Booking successful",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    createdAt: dayjs().subtract(3, "minutes").valueOf(),
  },
  {
    icon: {
      name: "wallet",
      iconColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
    },
    title: "Wallet credited",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    createdAt: dayjs("07-17-2022 18:36").valueOf(),
  },
  {
    icon: {
      name: "ev_station",
      iconColor: theme.palette.warning.main,
      backgroundColor: theme.palette.warning.light,
    },
    title: "Charging Complete",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    createdAt: dayjs("07-17-2022 18:36").valueOf(),
  },
  {
    icon: {
      name: "stars",
      iconColor: theme.palette.info.main,
      backgroundColor: theme.palette.info.light,
    },
    title: "ChargeSingh Points",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    createdAt: dayjs("07-08-2022 09:26").valueOf(),
  },
];

export const mockVehicles: MyVehicle[] = [
  {
    vID: "y1ehJdsfs6EXxuF-CEl7Q",
    userId: "sQSJXNATjrXlYjzisH1sNRYRLYJ2",
    ocppTag: "2f5e3195-4943-4728-8622-107cb61ee5aa",
    vTitle: "Rolls Royce Aventador",
    vOBCRating: 20,
    vCLookup: "",
    vBrand: "Nissan",
    vModel: "Model S",
    vBatteryCapacity: 22,
    vConnector: "value1",
    vImage: "",
    vNickName: "",
  },
  {
    vID: "u-W-YATZ3jBcM1ldB1Spo",
    userId: "sQSJXNATjrXlYjzisH1sNRYRLYJ2",
    ocppTag: "fbefb8dd-8254-49be-948d-d2efce6f65db",
    vTitle: "Fiat Expedition",
    vOBCRating: 30,
    vCLookup: "",
    vBrand: "BMW",
    vModel: "Countach",
    vBatteryCapacity: 22,
    vConnector: "value2",
    vImage: "",
    vNickName: "",
  },
];

export const mockMyVehicles: MyVehicle[] = [
  {
    ...mockVehicles[0],
    vTitle: "Jane's Car",
  },
];

export const mockUserOtherDetails: UserOtherDetails = {
  walletBalance: 108.54,
  csPoints: 195,
};

export const mockChargerTypes: ChargerType[] = [
  {
    name: "Type 2",
    icon: {
      src: IMAGES.MOCK_CHARGER_TYPE_2,
      width: 38,
      height: 30.4,
    },
    power: 22.0,
    price: 1.4,
  },
  {
    name: "CSS",
    icon: {
      src: IMAGES.MOCK_CHARGER_TYPE_CSS,
      width: 30.03,
      height: 32,
    },
    power: 50.0,
    price: 2.8,
  },
  {
    name: "CHAdeMO",
    icon: {
      src: IMAGES.MOCK_CHARGER_TYPE_CHADEMO,
      width: 35,
      height: 35,
    },
    power: 40.0,
    price: 1.9,
  },
];

// export function mockChargers(): Charger[] {
//   return Object.values(mockChargersJson);
// }

export const mockChargers = {
  gDFuNlSkRoFDiRg5ds474: {
    cID: "gDFuNlSkRoFDiRg5ds474",
    cTitle: "Charger 1: Ampang Point",
    cDetails:
      "Reiciendis quae maiores perspiciatis quod cum a eligendi accusamus. Eos tenetur mollitia mollitia nulla voluptate veniam. Ab alias non dignissimos dolore illo rem veniam hic.",
    cAddress: "Ampang Point",
    cLat: 3.15804593641219,
    cLong: 101.751096582155,
    cIcon: "",
    createdAt: 1176236540000,
    cPortType: "TYPE4",
    cPortsCount: 1,
    updatedAt: 1176236540000,
    cPower: 22,
    cPriceType: "FREE",
    cPropertyPrivacy: "PUBLIC",
    cAvailability: "NOT_AVAILABLE",
    cImage:
      "https://loremflickr.com/cache/resized/917_28354916367_d52a293d79_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  E185B16Kn7k1PHrgcmjdA: {
    cID: "E185B16Kn7k1PHrgcmjdA",
    cTitle: "Charger 2: Axis Atrium",
    cDetails:
      "Soluta aliquid ullam cum architecto aut. Nihil hic iure architecto odio dicta voluptates velit. Ratione recusandae nemo aliquam minima sapiente quidem. Aspernatur unde earum minima cupiditate. Quia labore molestias eaque error qui. Et rem nulla enim nulla a.",
    cAddress: "Axis Atrium",
    cLat: 3.13675387640715,
    cLong: 101.747283472368,
    cIcon: "",
    createdAt: 1256216520000,
    cPortType: "CCS",
    cPortsCount: 2,
    updatedAt: 1256216520000,
    cPower: 50,
    cPriceType: "PAID",
    cPropertyPrivacy: "PRIVATE",
    cAvailability: "BUSY",
    cImage:
      "https://loremflickr.com/cache/resized/65535_52156674074_f2498bb1e9_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  "A2-4zpNCFk6hABfxmXrlS": {
    cID: "A2-4zpNCFk6hABfxmXrlS",
    cTitle: "Charger 3: Galaxy Ampang",
    cDetails:
      "Eveniet voluptas accusantium consectetur impedit esse esse. Iure optio facilis minus iste expedita veniam nemo. Ut ad voluptas eveniet doloremque. Iusto iste sed ut eaque ipsam recusandae. Ipsum omnis eaque at.",
    cAddress: "Galaxy Ampang",
    cLat: 3.1476248380966,
    cLong: 101.755941197499,
    cIcon: "",
    createdAt: 1309314218000,
    cPortType: "TYPE2",
    cPortsCount: 1,
    updatedAt: 1309314218000,
    cPower: 7,
    cPriceType: "PAY-PER-USE",
    cPropertyPrivacy: "PERSONAL",
    cAvailability: "OFFLINE",
    cImage:
      "https://loremflickr.com/cache/resized/65535_52113007129_6224850c63_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  CyPXcV3DUfQ1pFtQvu9oT: {
    cID: "CyPXcV3DUfQ1pFtQvu9oT",
    cTitle: "Charger 4: Pandan Capital Mall",
    cDetails:
      "Ab molestiae ipsam eos id natus laudantium ullam iusto ipsa. Corporis temporibus quisquam sed assumenda sunt rerum eligendi. Neque dolore facilis.",
    cAddress: "Pandan Capital Mall",
    cLat: 3.12882572892681,
    cLong: 101.753907626335,
    cIcon: "",
    createdAt: 1662495665000,
    cPortType: "TYPE3",
    cPortsCount: 2,
    updatedAt: 1662495665000,
    cPower: 11,
    cPriceType: "THIRD-PARTY",
    cPropertyPrivacy: "PUBLIC",
    cAvailability: "NOT_AVAILABLE",
    cImage:
      "https://loremflickr.com/cache/resized/65535_51937623927_14f163fd31_z_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  KMgMJ5ggmpAL75uOQ6CCE: {
    cID: "KMgMJ5ggmpAL75uOQ6CCE",
    cTitle: "Charger 5: Spectrum Shopping Mall",
    cDetails:
      "Saepe saepe adipisci sit quia suscipit dolores debitis. Sunt exercitationem aliquid quam nam qui vero voluptatibus nobis dolor. Illum dignissimos nesciunt veritatis fugit assumenda.",
    cAddress: "Spectrum Shopping Mall",
    cLat: 3.14617926032347,
    cLong: 101.764662235581,
    cIcon: "",
    createdAt: 1809275010000,
    cPortType: "TYPE5",
    cPortsCount: 1,
    updatedAt: 1809275010000,
    cPower: 35,
    cPriceType: "OWN",
    cPropertyPrivacy: "PRIVATE",
    cAvailability: "OFFLINE",
    cImage:
      "https://loremflickr.com/cache/resized/65535_51928786577_0140416ef7_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  aIuwpdaZwQCGgmA4Lv8Fv: {
    cID: "aIuwpdaZwQCGgmA4Lv8Fv",
    cTitle: "Charger 6: Bangi Gateway",
    cDetails:
      "Deleniti atque reprehenderit porro fugiat dolores labore quis nulla. Architecto accusamus velit quod quo sequi. Eius similique alias reprehenderit sed quia facere repellendus voluptatum.",
    cAddress: "Bangi Gateway",
    cLat: 2.93386105247082,
    cLong: 101.765230610991,
    cIcon: "",
    createdAt: 1719468248000,
    cPortType: "TYPE1",
    cPortsCount: 2,
    updatedAt: 1719468248000,
    cPower: 25,
    cPriceType: "FREE",
    cPropertyPrivacy: "PERSONAL",
    cAvailability: "OFFLINE",
    cImage:
      "https://loremflickr.com/cache/resized/65535_49421685997_51de7e9109_z_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  kplxNFiiNEIbQUh1KzI2p: {
    cID: "kplxNFiiNEIbQUh1KzI2p",
    cTitle: "Charger 7: De Centrum Mall",
    cDetails:
      "Est ullam doloremque dicta ipsa quos. Consequuntur deserunt autem numquam repellat. Adipisci doloribus magnam culpa dicta. Laboriosam totam fugit assumenda dolore aperiam ad.",
    cAddress: "De Centrum Mall",
    cLat: 2.97983952744753,
    cLong: 101.738600197499,
    cIcon: "",
    createdAt: 1841783262000,
    cPortType: "TYPE1",
    cPortsCount: 1,
    updatedAt: 1841783262000,
    cPower: 40,
    cPriceType: "PAID",
    cPropertyPrivacy: "PUBLIC",
    cAvailability: "AVAILABLE",
    cImage:
      "https://loremflickr.com/cache/resized/65535_49300112488_37ff076030_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  a7GDWFZ0mY_HLHMfUFN6A: {
    cID: "a7GDWFZ0mY_HLHMfUFN6A",
    cTitle: "Charger 8: Evo Bangi",
    cDetails:
      "Laborum ipsum nobis. Qui odit sapiente incidunt blanditiis assumenda aperiam. Fugit explicabo nihil harum hic quam asperiores ex quaerat. Commodi voluptate sit sapiente quas.",
    cAddress: "Evo Bangi",
    cLat: 2.96296947611919,
    cLong: 101.755507750922,
    cIcon: "",
    createdAt: 1356393512000,
    cPortType: "TYPE5",
    cPortsCount: 2,
    updatedAt: 1356393512000,
    cPower: 30,
    cPriceType: "PAY-PER-USE",
    cPropertyPrivacy: "PRIVATE",
    cAvailability: "BUSY",
    cImage:
      "https://loremflickr.com/cache/resized/1909_45451358642_ff0c7f16a2_b_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  amAWra04yafDW3C8nLzXv: {
    cID: "amAWra04yafDW3C8nLzXv",
    cTitle: "Charger 9: ÆON Cheras Selatan Shopping Centre",
    cDetails:
      "Veniam in maiores nostrum eius maxime ullam cumque reiciendis. Voluptas magnam vel maiores consequatur quod praesentium mollitia. Aut adipisci hic laudantium. Exercitationem assumenda cupiditate nisi. Ea quo officia tenetur. Commodi laudantium possimus distinctio voluptatem exercitationem accusamus nulla culpa repellat.",
    cAddress: "ÆON Cheras Selatan Shopping Centre",
    cLat: 3.03375626051968,
    cLong: 101.764996777909,
    cIcon: "",
    createdAt: 1516155184000,
    cPortType: "TYPE3",
    cPortsCount: 1,
    updatedAt: 1516155184000,
    cPower: 22,
    cPriceType: "THIRD-PARTY",
    cPropertyPrivacy: "PERSONAL",
    cAvailability: "AVAILABLE",
    cImage:
      "https://loremflickr.com/cache/resized/917_28354916367_d52a293d79_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
  ct1_ePibmpIwUFkSasTG5: {
    cID: "ct1_ePibmpIwUFkSasTG5",
    cTitle: "Charger 10: BMC Mall (formerly AEON Makhota Cheras)",
    cDetails:
      "Facilis magni tenetur facilis repellendus ipsum consequuntur quam pariatur. Nesciunt a laudantium. Iure eos illum debitis excepturi deleniti eius. Molestiae sed aut maiores rerum incidunt. Harum a tempore placeat recusandae quis.",
    cAddress: "BMC Mall (formerly AEON Makhota Cheras)",
    cLat: 3.05507871314947,
    cLong: 101.787201557965,
    cIcon: "",
    createdAt: 1063531905000,
    cPortType: "CCS",
    cPortsCount: 2,
    updatedAt: 1063531905000,
    cPower: 50,
    cPriceType: "OWN",
    cPropertyPrivacy: "PUBLIC",
    cAvailability: "OFFLINE",
    cImage:
      "https://loremflickr.com/cache/resized/65535_52156674074_f2498bb1e9_c_640_480_nofilter.jpg",
    cPrice: 1,
    cBuilding: "",
    cArea: "",
    cCity: "",
    cState: "",
    cCountry: "",
  },
};

export const mockBookings: Booking[] = [
  {
    id: "1234",
    charger: {
      cID: "ct1_ePibmpIwUFkSasTG5",
      cTitle: "Charger 10: BMC Mall (formerly AEON Makhota Cheras)",
      cDetails:
        "Facilis magni tenetur facilis repellendus ipsum consequuntur quam pariatur. Nesciunt a laudantium. Iure eos illum debitis excepturi deleniti eius. Molestiae sed aut maiores rerum incidunt. Harum a tempore placeat recusandae quis.",
      cAddress: "BMC Mall (formerly AEON Makhota Cheras)",
      cLat: -58.0791,
      cLong: 7.9298,
      cIcon: "",
      createdAt: 1063531905000,
      cPortType: "CCS",
      cPortsCount: 2,
      updatedAt: 1063531905000,
      cPower: 50,
      cPriceType: "OWN",
      cPropertyPrivacy: "PUBLIC",
      cAvailability: "OFFLINE",
      cImage:
        "https://loremflickr.com/cache/resized/65535_52156674074_f2498bb1e9_c_640_480_nofilter.jpg",
      cPrice: 1,
    },
    bookTimeRange: {
      startAt: dayjs("22 August 2022 16:00").valueOf(),
      endAt: dayjs("22 August 2022 17:30").valueOf(),
    },
    status: BOOKING_STATUS.SUCCESS,
  },
];

export const mockBillings: Billing[] = [
  {
    id: "0987",
    charger: {
      cID: "ct1_ePibmpIwUFkSasTG5",
      cTitle: "Charger 10: BMC Mall (formerly AEON Makhota Cheras)",
      cDetails:
        "Facilis magni tenetur facilis repellendus ipsum consequuntur quam pariatur. Nesciunt a laudantium. Iure eos illum debitis excepturi deleniti eius. Molestiae sed aut maiores rerum incidunt. Harum a tempore placeat recusandae quis.",
      cAddress: "BMC Mall (formerly AEON Makhota Cheras)",
      cLat: -58.0791,
      cLong: 7.9298,
      cIcon: "",
      createdAt: 1063531905000,
      cPortType: "CCS",
      cPortsCount: 2,
      updatedAt: 1063531905000,
      cPower: 50,
      cPriceType: "OWN",
      cPropertyPrivacy: "PUBLIC",
      cAvailability: "OFFLINE",
      cImage:
        "https://loremflickr.com/cache/resized/65535_52156674074_f2498bb1e9_c_640_480_nofilter.jpg",
      cPrice: 1,
    },
    chargeTimeRange: {
      startAt: dayjs("24 August 2022 16:00").valueOf(),
      endAt: dayjs("24 August 2022 17:30").valueOf(),
    },
    status: BILLING_STATUS.COMPLETED,
  },
];

export const mockFAQs = [
  {
    id: "faq1",
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer: "This is some subtext which appears after expanding the accordian.",
  },
  {
    id: "faq2",
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut?",
    answer: "This is some subtext which appears after expanding the accordian.",
  },
  {
    id: "faq3",
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit?",
    answer: "This is some subtext which appears after expanding the accordian.",
  },
];
