import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Charger } from "../../../../interfaces";
import SearchChargerList from "../SearchChargerList/SearchChargerList";

import { MapRounded, SearchRounded } from "@mui/icons-material";
import Fuse from "fuse.js";
import _ from "lodash";
import SX from "../../../../constants/SX";
import { Box } from "@mui/system";

interface Props {
  chargers: Charger[];
  setChargers: (chargers: Charger[]) => void;
}

interface FilterForm {
  searchQuery: string;
  filterPower: number;
  filterAvailability: string;
}

let count = 0;
let filterChargersNew: Charger[] = [];
const SearchDesktopLayout = (props: Props) => {
  const {chargers, setChargers} = props;
  const theme = useTheme();
  const [searchData, setSearchData] = useState<Charger[]>(chargers);
  const [searchDataWithFilter, setSearchDataWithFilter] = useState(searchData);
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition>(
    {} as GeolocationPosition
  );
  const [requestLocation, setRequestLocation] = useState(false);
  const initialForm = {
    searchQuery: "",
    filterPower: "",
    filterAvailability: "",
  };

  const [filterForm, setFilterForm] = useState(initialForm);

  const options = {
    includeScore: true,
    useExtendedSearch: true,
    shouldSort: true,
    threshold: 0.7,
    distance: 10,
    // keys: ["location.address", "type.name", "cPower", "cPrice"],
    keys: [
      {
        name: "cAddress",
        weight: 0.2,
      },
      {
        name: "type.name",
        weight: 0.1,
      },
      {
        name: "cPower",
        weight: 0.7,
      },
      {
        name: "cPrice",
        weight: 0.7,
      },
    ],
  };

  // useEffect(() => {
  //   setSearchDataWithFilter(searchData);
  // }, [searchData]);

  function handleClearFilter() {
    setFilterForm(initialForm);
    setSearchData(chargers);
    setRequestLocation(!requestLocation);
  }

  const filteredChargers = chargers.filter(
    (charger) => charger.cAvailability === "Available"
  );

  function handleFilterData(e: any) {
    const name = e.target.name;
    const value = e.target.value;

    let selected: any = [];
    selected = {...selected, [name]: value};
    // console.log("selected", selected);
    //merge filtered form data with current selected
    setFilterForm({...filterForm, ...selected});
  }

  function filterFunction() {
    let query = filterForm.searchQuery;
    let tempChargerList: Charger[] = [];
    let finalChargerList: Charger[] = [];
    if (!query) {
      // setSearchData(chargers);
      finalChargerList = chargers;
    }

    const fuse = new Fuse(chargers, options);
    query = query.toLowerCase();
    const result = fuse.search(query);
    console.log("query", query);
    console.log("result", result);

    if (result.length) {
      result.forEach((item) => {
        finalChargerList.push(item.item);
      });
      // setSearchData(finalChargerList);
    } else {
      // if (query !== "") {
      //   setSearchData([]);
      // } else {
      //   setSearchData(chargers);
      // }
    }

    console.log("finalChargerList", finalChargerList);
    // document.title = `rendered ${count++} times`;
    console.log("filterForm", filterForm);

    const isAvlFilterOn = filterForm.filterAvailability !== "";
    const isPowerFilterOn = filterForm.filterPower !== "";
    const isSearchOn = filterForm.searchQuery !== "";

    console.log("isAvlFilterOn", isAvlFilterOn);
    console.log("isPowerFilterOn", isPowerFilterOn);
    console.log("isSearchOn", isSearchOn);
    filterChargersNew = finalChargerList.filter((charger) => {
      if (isAvlFilterOn && isPowerFilterOn) {
        console.log("running both");

        return (
          charger.cAvailability === filterForm.filterAvailability &&
          charger.cPower === (filterForm.filterPower as unknown as number)
        );
      } else if (isAvlFilterOn) {
        console.log("running isAvlFilterOn ");
        return charger.cAvailability === filterForm.filterAvailability;
      } else if (isPowerFilterOn) {
        console.log("running isPowerFilterOn");

        return charger.cPower === (filterForm.filterPower as unknown as number);
      }
      return finalChargerList;
    });
    console.log("filterChargersNew", filterChargersNew);
    if (filterChargersNew.length > 0) {
      console.log("searchData", searchData);
      console.log("filterChargersNew", filterChargersNew);
      setSearchData(filterChargersNew);
      // let result = _.unionBy(filterChargersNew, searchData, "id");
      // setSearchDataWithFilter(filterChargersNew);

      // console.log("result", result);
    } else {
      setSearchData(chargers);
    }
  }

  useEffect(() => {
    filterFunction();
  }, [filterForm]);
  // console.log(searchData);

  useEffect(() => {
    const call = async () => {
      if (!currentLocation.coords) return;
      // if (requestLocation) {
      //   navigator.geolocation.getCurrentPosition((position) => {
      //     setCurrentLocation(position);
      //   });
      // }
      console.log("currentLocation_useEffect", currentLocation.coords.latitude);
    };

    call();
  }, [requestLocation, currentLocation]);

  function handleLocation() {
    console.log(requestLocation);
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentLocation(position);
    });
  }

  return (
    <Grid container justifyContent={"center"}>
      <Box
        sx={{
          ...SX.CENTER_EVERYTHING,
          width: "100%",
          background: theme.palette.primary.main,
          padding: "2rem 1rem",
        }}
      >
        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs={10} md={8}>
            <Typography variant={"h3"}>Find Charger</Typography>
          </Grid>

          <Grid item xs={10} md={8}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputBase
                fullWidth
                sx={{ml: 1, flex: 1}}
                placeholder={"Sungai Besi"}
                name={"searchQuery"}
                inputProps={{"aria-label": "search google maps"}}
                onChange={(e) => handleFilterData(e)}
              />
              <IconButton type="button" sx={{p: "10px"}} aria-label="search">
                <SearchRounded/>
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Grid item xs={10} md={10} sx={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
      }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}

        >
          <Grid item xs={10} md={8}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl size="small" sx={{m: 1, minWidth: 120}}>
                <InputLabel id="filter-power-label">Power(KW)</InputLabel>
                <Select
                  labelId="filter-power"
                  id="filter-power"
                  value={filterForm.filterPower as string}
                  name={"filterPower"}
                  label="Power(KW)"
                  onChange={(e) => handleFilterData(e)}
                >
                  {_.uniqBy(chargers, "cPower").map((item) => (
                    <MenuItem key={item.cID} value={item.cPower}>
                      {item.cPower}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{m: 1, minWidth: 120}}>
                <InputLabel id="filter-availability-label">
                  Availability
                </InputLabel>
                <Select
                  labelId="filter-availability"
                  name="filterAvailability"
                  id="filter-availability"
                  value={filterForm.filterAvailability}
                  label="Availability"
                  onChange={(e) => handleFilterData(e)}
                >
                  {_.uniqBy(chargers, "availability").map((item) => (
                    <MenuItem key={item.cID} value={item.cAvailability}>
                      {item.cAvailability}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant={"text"}
                size={"small"}
                onClick={handleClearFilter}
              >
                clear filter
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant={"text"}
              color={"info"}
              size={"small"}
              onClick={() => handleLocation()}
              startIcon={<MapRounded/>}
            >
              Show Distance
            </Button>
          </Grid>
        </Grid>
        <SearchChargerList
          chargers={searchData}
          currentLocation={currentLocation}
        />
      </Grid>
    </Grid>
  );
};

export default SearchDesktopLayout;
