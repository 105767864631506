import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface Props extends ButtonProps {
  color:
    | "inherit"
    | "info"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "warning";
  image: {
    url: string;
    width: number;
    height: number;
  };
  label: string;
}

const IndexActionButton = (props: Props) => {
  const { color, image, label } = props;
  return (
    <Button
      variant="contained"
      color={color}
      disableElevation
      startIcon={
        <img
          src={image.url}
          width={image.width}
          height={image.height}
          alt={label}
        />
      }
      sx={{
        borderRadius: "1rem",
        padding: "1rem",
        fontWeight: "bold",
        textAlign: "start",
      }}
    >
      {label}
    </Button>
  );
};

export default IndexActionButton;
