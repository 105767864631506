import { Grid, Stack } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}
const AuthPageLayout = (props: Props) => {
  const { children } = props;
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthPageLayout;
