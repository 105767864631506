import { Redirect, Route, RouteProps } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebaseApp";
import BackdropComponent from "../components/core/Others/BackDropComponent";
import Index from "../pages";
import React from "react";
import { useAuth } from "../providers/AuthProvider";
import MyProfile from "../pages/my-profile";
import ProfileChecker from "../pages/profile-checker";

const PrivateRoute = (routeProps: RouteProps) => {
  // const { user, loading } = useAuth();
  const [user, loading, error] = useAuthState(auth);
  if (loading) return <BackdropComponent />;
  if (!loading && Boolean(user) && user?.emailVerified) {
    return <Route {...routeProps} />;
  } else if (!loading && Boolean(user) && !user?.emailVerified) {
    return <Route render={(props) => <MyProfile />} />;
  }

  if (!Boolean(user)) return <Redirect to="/login" />;
  if (error) return <Redirect to="/login" />;

  return <Redirect to="/login" />;
};

export default PrivateRoute;
