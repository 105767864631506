import React from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";

import * as Yup from "yup";

import CSLink from "../CSLink/CSLink";
import { useSnackbar } from "notistack";
import IMAGES from "../../../constants/IMAGES";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../../services/firebaseApp";
import { Link } from "react-router-dom";
import BackdropComponent from "../Others/BackDropComponent";

interface Props {
  // switchPage: () => void;
}

const initialValues = {
  email: "",
  password: "",
};
const RegisterComponent = (props: Props) => {
  // const { switchPage } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  //TODO make it as export and separate file
  const SignUpSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Firstname is required"),
    //
    // lastName: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Lastname is required"),
    //
    // phoneNumber: Yup.string()
    //   .required("Phone number is required")
    //   .matches(
    //     /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
    //     "Invalid phone number"
    //   ),

    email: Yup.string().email().required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      await createUserWithEmailAndPassword(values.email, values.password);
      //Todo after this run a cloud function to send email on auth trigger.
      if (error) enqueueSnackbar(error.message, { variant: "error" });
    },
    validateOnChange: true,
    validateOnBlur: true,
  });
  const { values, errors, touched, handleChange, isValid } = formik;

  return (
    <>
      <Stack
        sx={{
          width: "clamp(275px, 100%)",
          padding: "3rem 2rem",
        }}
        spacing="4rem"
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={IMAGES.APP_LOGO} width={200} height={87.06} alt="CS Logo" />
        </Box>

        <Stack
          alignItems="center"
          spacing={2}
          component={"form"}
          onSubmit={formik.handleSubmit}
        >
          <Typography variant="h2">{"Sign Up"}</Typography>

          <TextField
            variant="outlined"
            placeholder={"Email"}
            name="email"
            type="email"
            fullWidth
            // onChange={(e) => setEmail(e.target.value)}
            value={values.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />

          <TextField
            variant="outlined"
            placeholder={"Password"}
            name="password"
            type="password"
            fullWidth
            // onChange={(e) => setPassword(e.target.value)}
            value={values.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!isValid}
          >
            {"Create My Account"}
          </Button>
          <Stack
            direction={isSm ? "column" : "row"}
            alignItems="center"
            justifyContent="center"
            spacing={1}
            marginTop="1rem"
          >
            <Typography variant="body1">
              {"Already have an account?"}
            </Typography>

            <Link to={"/login"}>
              <Typography
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {"Authentication Now"}
              </Typography>
            </Link>
          </Stack>
        </Stack>

        <Box sx={{ textAlign: "center", color: "textSecondary" }}>
          {"By signing up, you agree to the "}
          <CSLink to="/terms-and-conditions" underlined={"underlined"}>
            {"Terms & Conditions"}
          </CSLink>
          {" and "}
          <CSLink to="/privacy-policy" underlined={"underlined"}>
            {"Privacy Policy"}
          </CSLink>
        </Box>
      </Stack>
      <>{loading ? <BackdropComponent /> : null}</>
    </>
  );
};

export default RegisterComponent;
