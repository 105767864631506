import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { SnackbarProvider } from "notistack";
import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "../providers/AuthProvider";
import theme from "../utils/theme";

const queryClient = new QueryClient();

interface Props {
  children: ReactNode;
}

const AppLayout = (props: Props) => {
  const { children } = props;
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2500}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              preventDuplicate
            >
              {children}
            </SnackbarProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default AppLayout;
