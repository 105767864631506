import {
  AccessTimeRounded,
  CalendarMonthRounded,
  DirectionsRounded,
} from "@mui/icons-material";
import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Booking } from "../../../interfaces";
import SX from "../../../constants/SX";

interface Props {
  booking: Booking;
}

const BookingDetails = (props: Props) => {
  const { booking } = props;

  return (
    <Grid container>
      <Grid item xs={9}>
        <Stack spacing={2}>
          <Typography variant="body1" fontWeight="bold">
            {`Charger: ${booking.charger.cID}`}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{ ...SX.ELLIPSIS }}
          >
            {booking.charger.cAddress}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <CalendarMonthRounded color="primary" fontSize="small" />
                <Typography
                  variant="subtitle2"
                  fontSize="0.75rem"
                  fontWeight="bold"
                >
                  {dayjs(booking.bookTimeRange.startAt).format("DD MMM YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AccessTimeRounded color="primary" fontSize="small" />
                <Typography
                  variant="subtitle2"
                  fontSize="0.75rem"
                  fontWeight="bold"
                >
                  {`${dayjs(booking.bookTimeRange.startAt).format(
                    "HH:mm"
                  )} - ${dayjs(booking.bookTimeRange.endAt).format("HH:mm")}`}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={3}>
        <Button
          component="a"
          target="_blank"
          color="info"
          size="small"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "0.8rem",
            textTransform: "capitalize",
            padding: "1rem",
            borderRadius: "1rem",
            "& .MuiButton-startIcon": {
              margin: 0,
            },
          }}
          startIcon={<DirectionsRounded fontSize="large" color="info" />}
        >
          {"Directions"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default BookingDetails;
