import { Stack } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}
const IndexPageLayout = (props: Props) => {
  const { children } = props;
  return (
    <Stack
      spacing={2}
      sx={{ padding: "1rem", width: "clamp(300px, 100%, 400px)" }}
    >
      {children}
    </Stack>
  );
};

export default IndexPageLayout;
