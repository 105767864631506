import { CallRounded, HelpOutlineRounded } from "@mui/icons-material";
import {
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CS from "../../../../constants/CS";
import IndexPageLayout from "../../../../layouts/IndexPageLayout";
import CSLink from "../../../core/CSLink/CSLink";

interface Props {}

const IndexNeedHelp = (props: Props) => {
  const {} = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <IndexPageLayout>
      <Typography variant="h3">{"Need Help?"}</Typography>
      <Stack direction={isSm ? "column" : "row"} spacing={2}>
        <CSLink to={`tel:+${CS.CONTACT}`}>
          <Card sx={{ padding: "1rem", display: "flex", columnGap: "0.5rem" }}>
            <CallRounded color="success" fontSize="small" />
            <Stack>
              <Typography variant="subtitle2" color="textSecondary">
                {"Call our Helpline"}
              </Typography>
              <Typography variant="h4" fontSize="1.2rem">
                {CS.CONTACT}
              </Typography>
            </Stack>
          </Card>
        </CSLink>
        <CSLink to="/help-centre">
          <Card sx={{ padding: "1rem", display: "flex", columnGap: "0.5rem" }}>
            <HelpOutlineRounded color="info" fontSize="small" />
            <Stack>
              <Typography variant="subtitle2" color="textSecondary">
                {"FAQ & Tutorials"}
              </Typography>
              <Typography variant="h4" fontSize="1.2rem">
                {"Help Centre"}
              </Typography>
            </Stack>
          </Card>
        </CSLink>
      </Stack>
    </IndexPageLayout>
  );
};

export default IndexNeedHelp;
