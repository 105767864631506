import { MyVehicle } from "../interfaces/Vehicle";
import { auth, db } from "./firebaseApp";
import { child, get, ref, set } from "firebase/database";
import { UserRef } from "../constants/FirebaseRefs";

export const VehicleService = {
  async getUserVehicleList() {
    const userID = auth.currentUser?.uid;
    if (!userID) return;

    const dbRef = ref(db);
    const snapshot = await get(
      child(dbRef, `${UserRef.userVehicleList}/${userID}`)
    );
    try {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        return snapshot.val();
      }
    } catch (error) {
      console.error(error);
    }
  },
  async updateUserVehicle(formState: MyVehicle, vID: string) {
    const userID = auth.currentUser?.uid;

    if (vID !== "" && userID) {
      try {
        await set(ref(db, UserRef.userVehicleList + "/" + userID + "/" + vID), {
          ...formState,
        });
        return true;
      } catch (error) {
        return error;
      }
    }
  },
  async getUserVehicle() {},
  async deleteUserVehicle() {},
};
