import { getDistance } from "geolib";
import _ from "lodash";

export function getCurrentLocation(): GeolocationPosition {
  return {} as GeolocationPosition;
}

export function nativeLocation(
  latitude: number,
  longitude: number,
  position: GeolocationPosition
): number {
  return _.round(
    getDistance(
      {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
      {
        latitude: latitude,
        longitude: longitude,
      }
    ) / 1000,
    1
  );
}
