import React, { CSSProperties, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  anchorstyles?: CSSProperties;
  underlined?: string | "none";
  children: ReactNode;
}

const LinkComponent = (props: Props) => {
  const { anchorstyles, underlined, children } = props;
  return (
    <Link
      {...props}
      style={{
        textDecoration: underlined ? "underlined" : "none",
        color: "black",
        ...anchorstyles,
      }}
    >
      {children}
    </Link>
  );
};

export default LinkComponent;
