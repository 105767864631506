import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { MyVehicle } from "../../../interfaces/Vehicle";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAuth } from "firebase/auth";
import { VehicleService } from "../../../services/db.vehicle.service";

interface Props {
  selectedVehicle: MyVehicle;
  setShowAddVehicle: Function;
  updateType: string;
}

const AddMyVehicle = (props: Props) => {
  const { selectedVehicle, setShowAddVehicle } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);

  // console.log("selectedVehicle", selectedVehicle);
  // const [formState, setFormState] = useState({
  //   vTitle: selectedVehicle.vTitle,
  //   vOBCRating: selectedVehicle.vOBCRating,
  //   vCLookup: selectedVehicle.vCLookup,
  //   vBrand: selectedVehicle.vBrand,
  //   vModel: selectedVehicle.vModel,
  //   vBatteryCapacity: selectedVehicle.vBatteryCapacity,
  //   vConnector: selectedVehicle.vConnector,
  // } as MyVehicle);
  // console.log("selectedVehicle", selectedVehicle.vID);
  // const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const errorValue = Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("*It is required");

  const VehicleRegSchema = Yup.object().shape({
    // firstName: Yup.string()

    //
    // lastName: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Lastname is required"),
    //
    // phoneNumber: Yup.string()
    //   .required("Phone number is required")
    //   .matches(
    //     /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
    //     "Invalid phone number"
    //   ),

    vTitle: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
    vBrand: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
    vModel: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
    vOBCRating: Yup.number()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
    vBatteryCapacity: Yup.number()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
    vConnector: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
    vCLookup: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*It is required"),
  });
  const formik = useFormik({
    initialValues: {
      vID: selectedVehicle.vID,
      vTitle: selectedVehicle.vTitle,
      vOBCRating: selectedVehicle.vOBCRating as number,
      vCLookup: selectedVehicle.vCLookup,
      vBrand: selectedVehicle.vBrand,
      vModel: selectedVehicle.vModel,
      vBatteryCapacity: selectedVehicle.vBatteryCapacity,
      vConnector: selectedVehicle.vConnector,
    } as MyVehicle,
    validationSchema: VehicleRegSchema,
    onSubmit: async (values) => {
      const vehicleObject = {
        ...values,
        vID: selectedVehicle.vID,
        useID: getAuth().currentUser?.uid,
        ocppTag: `${selectedVehicle.vID}#${getAuth().currentUser?.uid}`,
      };

      console.log("vehicleObject", vehicleObject);
      // setFormState({ ...formState, ...vehicleObject });

      const response = await VehicleService.updateUserVehicle(
        vehicleObject,
        vehicleObject.vID
      );
      if (response === true) {
        // disable all
        setIsSubmitted(true);
        setShowAddVehicle(false);
      }
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
  });
  const { values, errors, touched, handleChange, isValid } = formik;

  const formFields = [
    {
      id: "vTitle",
      label: "Vehicle Name",
      placeholder: "",
      errors: errors.vTitle,
      type: "text",
    },
    {
      id: "vBrand",
      label: "Vehicle Brand",
      placeholder: "",
      errors: errors.vBrand,
      type: "text",
    },
    {
      id: "vModel",
      label: "Vehicle Model",
      placeholder: "",
      errors: errors.vModel,
      type: "text",
    },
    {
      id: "vOBCRating",
      label: "Vehicle  OBCRating",
      placeholder: "",
      errors: errors.vOBCRating,
      type: "number",
      options: [
        {
          id: "123",
          text: "sdfsdfsdfs sdf sd",
          value: "s sdfsfs",
        },
        {
          id: "34324",
          text: "value 2",
          value: "value 2",
        },
      ],
    },
    {
      id: "vConnector",
      label: "Vehicle Connector",
      placeholder: "",
      errors: errors.vConnector,
      type: "select",
      options: [
        {
          id: "0",
          text: "Please Select",
          value: "none",
        },
        {
          id: "234",
          text: "Value 1",
          value: "value1",
        },
        {
          id: "2342",
          text: "Value 2",
          value: "value2",
        },
      ],
    },
    {
      id: "vBatteryCapacity",
      label: "Vehicle Battery Capacity",
      placeholder: "",
      errors: errors.vBatteryCapacity,
      type: "number",
      options: [
        {
          id: "655",
          text: "wrwerww",
          value: "ssdfsd sdfs",
        },
      ],
    },
    {
      id: "vCLookup",
      label: "Charger Type",
      placeholder: "",
      errors: errors.vCLookup,
      type: "text",
      options: [
        {
          id: "878",
          text: "werwrw",
          value: "werwerw",
        },
      ],
    },
  ];

  // useEffect(() => {
  //   console.log("values", values);
  //   const call = async () => {
  //     if (await updateUserVehicle(formState, "")) {
  //       // show alert success
  //     } else {
  //       // vehicle not added
  //     }
  //   };
  //
  //   call();
  // }, [formState]);

  // const handleOnChange = (event: FormEvent) => {
  //   console.log("Form1::onChange", event.type.valueOf());
  //
  //   handleChange(event);
  //   console.log(values);
  // };

  return (
    <Paper
      sx={{
        width: "90%",
        maxWidth: "1000px",
        padding: "1rem",
        borderRadius: "1rem",
        margin: "1rem",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent={"center"}
        spacing={2}
        component={"form"}
        onSubmit={formik.handleSubmit}
        sx={{ marginLeft: "5%", marginRight: "5%", textAlign: "center" }}
      >
        <Box>
          <Typography variant={"caption"}>
            Vehicle ID: {selectedVehicle.vID}
          </Typography>
        </Box>
        {formFields.map((item) =>
          item.type === "select" ? (
            <FormControl key={item.id} fullWidth>
              <Select
                disabled={isSubmitted}
                placeholder={item.label}
                name={item.id}
                onChange={handleChange}
                value={
                  values[item.id as keyof MyVehicle]
                    ? values[item.id as keyof MyVehicle]
                    : ""
                }
                style={{
                  textAlign: "left",
                }}
              >
                {item.options &&
                  item.options.map((menuItem) => (
                    <MenuItem key={menuItem.id} value={menuItem.value}>
                      {menuItem.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              key={item.id}
              label={item.label}
              defaultValue={values[item.id as keyof MyVehicle]}
              variant="outlined"
              placeholder={item.label}
              name={item.id}
              type={item.type}
              onChange={handleChange}
              fullWidth
              helperText={item.errors}
              disabled={isSubmitted}
            />
          )
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!isValid || isSubmitted}
        >
          {"Save"}
        </Button>
      </Stack>
    </Paper>
  );
};

export default AddMyVehicle;
