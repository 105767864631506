import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Index from "../pages";
import Search from "../pages/search";
import Login from "../pages/login";
import PublicRoute from "./PublicRoute";
import myProfile from "../pages/my-profile";
import WSTest from "../pages/wsTest";
import Register from "../pages/register";
import HelpCentrePage from "../pages/help-centre";
import ForgotPassword from "../pages/forgot-password";
import MyVehiclePage from "../pages/my-vehicles";

const RoutesComponent = () => {
  return (
    <Switch>
      {/*PrivateRoute*/}
      <PrivateRoute exact path="/" component={Index} />
      <PrivateRoute exact path="/search" component={Search} />
      <PrivateRoute exact path="/search/:vID" component={Search} />
      <PrivateRoute exact path="/my-profile" component={myProfile} />

      {/*My Vehicle page*/}
      <PrivateRoute exact path="/my-vehicles" component={MyVehiclePage} />
      {/*public pages*/}
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/register" component={Register} />
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />

      <PrivateRoute exact path="/ws" component={WSTest} />
      <PrivateRoute exact path="/help-centre" component={HelpCentrePage} />
      {/*  Chargers*/}
      <PrivateRoute exact path="/chargers/:cID" component={Search} />
    </Switch>
  );
};

export default RoutesComponent;
