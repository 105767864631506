import {
  AccountBalanceWalletOutlined,
  StarsRounded,
} from "@mui/icons-material";
import { Box, Card, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import SX from "../../../../constants/SX";
import { UserOtherDetails } from "../../../../interfaces";
import IndexPageLayout from "../../../../layouts/IndexPageLayout";
import { formatCurrency, formatNumber } from "../../../../utils/formatter";

interface Props {
  details: UserOtherDetails;
}

const IndexUserOtherDetails = (props: Props) => {
  const { details } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...SX.CENTER_EVERYTHING,
        width: "100%",
        background: `linear-gradient(to bottom, ${theme.palette.primary.main} 50%, ${theme.palette.background.default} 50%)`,
        padding: "2rem 1rem",
      }}
    >
      <IndexPageLayout>
        <Card sx={{ padding: "1rem" }}>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                borderRight: `0.5px dashed ${theme.palette.grey[300]}`,
              }}
            >
              <Stack direction="row" spacing={1}>
                <AccountBalanceWalletOutlined color="primary" />
                <Stack justifyContent="space-between">
                  <Typography variant="body2" fontWeight="bold">
                    {formatCurrency(details.walletBalance)}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {"Wallet Balance"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6} display="flex" paddingLeft="1rem">
              <Stack direction="row" spacing={1}>
                <StarsRounded color="primary" />
                <Stack justifyContent="space-between">
                  <Typography variant="body2" fontWeight="bold">
                    {formatNumber(details.csPoints)}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {"ChargeSingh Points"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </IndexPageLayout>
    </Box>
  );
};

export default IndexUserOtherDetails;
