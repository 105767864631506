import React from "react";
import AppLayout from "../layouts/AppLayout";
import AuthPageLayout from "../layouts/AuthPageLayout";
import RegisterComponent from "../components/core/Authentication/Register";

const RegisterPage = () => {
  return (
    <AppLayout>
      <AuthPageLayout>
        <RegisterComponent />
      </AuthPageLayout>
    </AppLayout>
  );
};

export default RegisterPage;
