const IMAGES = {
  APP_LOGO: "/cs-logo.svg",
  CHARGER: "/charger.svg",
  CHARGING_STATION: "/charging-station.svg",
  MOCK_CHARGER_TYPE_2: "/mock-images/charger-type-2.svg",
  MOCK_CHARGER_TYPE_ALL: "/mock-images/charger-type-all.svg",
  MOCK_CHARGER_TYPE_CHADEMO: "/mock-images/charger-type-chademo.svg",
  MOCK_CHARGER_TYPE_CSS: "/mock-images/charger-type-css.svg",
  MOCK_CHARGING_STATION_1: "/mock-images/charging-station-1.png",
  MOCK_PROFILE_PICTURE: "/mock-images/profile.png",
  MOCK_VEHICLE_BMW: "/mock-images/vehicle-bmw.svg",
  MALL1: "/mock-images/mall1.jpg",
  MALL3: "/mock-images/mall3.jpg",
  MALL4: "/mock-images/mall4.jpg",
  MALL: "/mock-images/mall.jpg",
};

export default IMAGES;
