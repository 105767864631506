import IndexActions from "../components/pages/index/IndexActions/IndexActions";
import IndexBookings from "../components/pages/index/IndexBookings/IndexBookings";
import IndexHeader from "../components/pages/index/IndexHeader/IndexHeader";
import IndexNeedHelp from "../components/pages/index/IndexNeedHelp/IndexNeedHelp";
import IndexUserOtherDetails from "../components/pages/index/IndexUserOtherDetails/IndexUserOtherDetails";
import PageLayout from "../layouts/PageLayout";
import { mockBookings, mockUserOtherDetails } from "../utils/mocks";
import React, { useEffect } from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import { usePWAInstall } from "react-use-pwa-install";

const Index = () => {
  const [open, setOpen] = React.useState(false);
  const install = usePWAInstall();

  const handleClick = () => {
    install();
    setOpen(false);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    console.log(install);
    setOpen(Boolean(install));
  }, [install]);

  return (
    <PageLayout>
      <IndexHeader />
      <IndexUserOtherDetails details={mockUserOtherDetails} />
      <IndexActions />
      <IndexBookings bookings={mockBookings} />
      <IndexNeedHelp />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Install Charge Singh App"
        action={
          <React.Fragment>
            <Button color="primary" size="small" onClick={handleClick}>
              Install
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </PageLayout>
  );
};

export default Index;
