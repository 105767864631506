import { CallRounded, PlayCircleOutlineRounded } from "@mui/icons-material";
import { Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CS from "../../../constants/CS";
import CSLink from "../../core/CSLink/CSLink";

const HelpCentreLinks = () => {
  return (
    <Grid container spacing={2} maxWidth="500px">
      <Grid item xs={12}>
        <CSLink to={`tel:+${CS.CONTACT}`}>
          <Card sx={{ padding: "1rem" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <CallRounded fontSize="large" color="success" />
              <Stack>
                <Typography variant="caption" color="textSecondary">
                  {"Call our Helpline"}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {CS.CONTACT}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </CSLink>
      </Grid>
      <Grid item xs={12}>
        <CSLink to={CS.YOUTUBE_TUTORIAL}>
          <Card sx={{ padding: "1rem" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <PlayCircleOutlineRounded fontSize="large" color="error" />
              <Stack>
                <Typography variant="caption" color="textSecondary">
                  {"Learn how to use our App and Products"}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {"video Tutorials"}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </CSLink>
      </Grid>
    </Grid>
  );
};

export default HelpCentreLinks;
