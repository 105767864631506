import React, { createContext, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebaseApp";

interface DefaultValue {
  user: any;
  loading: any;
  error: any;
}

const defaultValue: DefaultValue = {
  user: null,
  loading: false,
  error: false,
};
export const AuthContext = createContext(defaultValue);

const AuthProvider = ({ children }: any) => {
  const [user, loading, error] = useAuthState(auth);

  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth: () => DefaultValue = () => useContext(AuthContext);
