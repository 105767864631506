import { Backdrop, CircularProgress } from "@mui/material";
import grey from "@mui/material/colors/grey";
import React from "react";

export default function BackdropComponent() {
  return (
    <Backdrop open sx={{ background: grey[50] }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
