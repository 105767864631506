import { ChevronRightRounded } from "@mui/icons-material";
import { Button, Card, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Booking } from "../../../../interfaces";
import IndexPageLayout from "../../../../layouts/IndexPageLayout";
import BookingDetails from "../../../core/BookingDetails/BookingDetails";
import CSLink from "../../../core/CSLink/CSLink";

interface Props {
  bookings: Booking[];
}

const IndexBookings = (props: Props) => {
  const { bookings } = props;
  const firstBooking = bookings[0];
  const theme = useTheme();

  return (
    <IndexPageLayout>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{"Upcoming Bookings"}</Typography>
        {bookings.length > 1 ? (
          <CSLink to="/my-bookings">
            <Button
              color="inherit"
              size="small"
              endIcon={<ChevronRightRounded />}
              sx={{
                textTransform: "capitalize",
                color: theme.palette.grey[500],
              }}
            >
              {"view all"}
            </Button>
          </CSLink>
        ) : null}
      </Stack>
      {bookings.length ? (
        <Card sx={{ padding: "1rem", width: "clamp(300px, 100%, 450px)" }}>
          <BookingDetails booking={firstBooking} />
        </Card>
      ) : (
        <Typography variant="body1" color="grey.300">
          {"No Bookings"}
        </Typography>
      )}
    </IndexPageLayout>
  );
};

export default IndexBookings;
