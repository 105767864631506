import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PageLayout from "../layouts/PageLayout";
import PageHeader from "../components/core/PageHeader/PageHeader";
import MyProfileDetails from "../components/pages/my-profile/MyProfileDetails";

const MyProfilePage = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PageLayout containerSx={{ px: isSm ? "0" : "4rem" }}>
      <PageHeader title={"My Profile"} />
      {/*<MyProfilePicture user={user} />*/}
      <MyProfileDetails />
    </PageLayout>
  );
};

export default MyProfilePage;
