import { Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import LoginComponent from "../Authentication/Login";
import RegisterComponent from "../Authentication/Register";

const CSLoginButton = () => {
  const [open, setOpen] = useState(false);
  const [onLoginPage, setOnLoginPage] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const switchPage = () => {
    console.log("wut");
    setOnLoginPage(!onLoginPage);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        sx={{
          textTransform: "capitalize",
          border: "2px solid black",
          fontWeight: "bold",
        }}
        onClick={handleOpen}
      >
        {"Login"}
      </Button>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        {onLoginPage ? <LoginComponent /> : <RegisterComponent />}
      </Dialog>
    </>
  );
};

export default CSLoginButton;
