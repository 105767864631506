import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import React from "react";
import { MyVehicle } from "../../../interfaces/Vehicle";
import { Delete, Edit } from "@mui/icons-material";

interface Props {
  vehicles: MyVehicle[];
  setSelectedVehicle: Function;
  setShowAddVehicle: Function;
  setUpdateType: Function;
}

const MyVehicleList = (props: Props) => {
  const { vehicles, setSelectedVehicle, setShowAddVehicle, setUpdateType } =
    props;
  // const theme = useTheme();
  // const isSm = useMediaQuery(theme.breakpoints.down("md"));

  function goToCharger(vehicle: MyVehicle) {}

  function handleVehicleSelection(vehicle: MyVehicle) {
    setSelectedVehicle(vehicle);
    setShowAddVehicle(vehicle);
    setUpdateType("Edit");
  }

  function handleRemoveVehicle(vehicle: MyVehicle) {
    alert("Are you sure to delete " + vehicle.vTitle);
  }

  return (
    <Paper
      sx={{
        width: "90%",
        maxWidth: "1000px",
        padding: "1rem",
        borderRadius: "1rem",
      }}
    >
      <List>
        {vehicles.map((vehicle) => (
          <ListItem key={vehicle.vID} divider>
            {/*<ListItemAvatar*/}
            {/*  sx={{*/}
            {/*    width: "100px",*/}
            {/*    height: "100px",*/}
            {/*    background: theme.palette.background.default,*/}
            {/*    borderRadius: "5px",*/}
            {/*    padding: "1rem",*/}
            {/*    marginRight: "1rem",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img src={vehicle.vImage} alt={vehicle.vBrand} />*/}
            {/*</ListItemAvatar>*/}
            <ListItemText>
              <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography>{vehicle.vTitle}</Typography>

                  <Typography color="textSecondary">
                    {`${vehicle.vBrand} ${vehicle.vModel}`}
                  </Typography>

                  <Typography color="grey.500">
                    {`${vehicle.vBrand} • ${vehicle.vBatteryCapacity} kwh`}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemText>
            <ListItemSecondaryAction>
              <Button
                variant={"text"}
                color={"secondary"}
                startIcon={<Edit />}
                onClick={() => handleVehicleSelection(vehicle)}
              >
                Edit
              </Button>
              <Button
                variant={"text"}
                color={"error"}
                startIcon={<Delete />}
                onClick={() => handleRemoveVehicle(vehicle)}
              >
                Remove
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default MyVehicleList;
