import { signInWithEmailAndPassword, User } from "firebase/auth";
import { auth } from "./firebaseApp";
import { UserLoginForm } from "../interfaces";

// default value
interface DefaultValue {
  user: User | null;
  loading: boolean;
  role: string;
  roleTitle: string;
}

const defaultValue: DefaultValue = {
  user: null,
  loading: false,
  role: "",
  roleTitle: "",
};

export const AuthenticationService = {
  async loginWithEmail(form: UserLoginForm) {
    const { email, password } = form;
    return await signInWithEmailAndPassword(auth, email, password);
  },

  async signOut() {
    await auth.signOut();
  },
};

// export default auth;
