import React from "react";
import "./App.css";
import Routes from "./routers/Routes";
import AppLayout from "./layouts/AppLayout";

// Client-side cache, shared for the whole session of the user in the browser.
// const clientSideEmotionCache = createEmotionCache();

// interface MyAppProps extends ComponentProps<any> {
//   emotionCache?: EmotionCache;
// }
function App() {
  return (
    <AppLayout>
      <Routes />
    </AppLayout>
  );
}

export default App;
