import { useMediaQuery, useTheme } from "@mui/material";

import React, { useMemo, useState } from "react";
import SearchDesktopLayout from "../components/pages/search/SearchDesktopLayout/SearchDesktopLayout";
import { Charger } from "../interfaces";
import PageLayout from "../layouts/PageLayout";
import { mockChargers } from "../utils/mocks";
import { useParams } from "react-router-dom";
import ChargerDetailsComponent from "../components/pages/search/SearchChargerDetails/ChargerDetailsComponent";

const Search = () => {
  const theme = useTheme();
  const { cID } = useParams<{ cID?: string }>();

  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const mockChargersValues = Object.values(mockChargers);
  const [chargers, setChargers] = useState<Charger[]>(mockChargersValues);
  // const [favoriteChargers, setFavoriteChargers] = useState<string[]>([
  //   mockChargers[0].cID,
  // ]);

  //get list of chargers
  const selectedCharger = useMemo(
    () => chargers.find((charger) => charger.cID === cID),
    [chargers, cID]
  );
  return (
    <PageLayout>
      <>
        {!cID ? (
          <SearchDesktopLayout chargers={chargers} setChargers={setChargers} />
        ) : (
          <ChargerDetailsComponent
            charger={selectedCharger || ({} as Charger)}
          />
        )}

        {/*<SearchMap />*/}
      </>
    </PageLayout>
  );
};

export default Search;
