import React, { useEffect, useState } from "react";
import PageHeader from "../components/core/PageHeader/PageHeader";
import MyVehicleList from "../components/pages/my-vehicle/MyVehicleList";
import PageLayout from "../layouts/PageLayout";
import { Button, Grid } from "@mui/material";
import { AddRounded, Cancel } from "@mui/icons-material";
import AddMyVehicle from "../components/pages/my-vehicle/AddMyVehicle";
import { MyVehicle } from "../interfaces/Vehicle";
import { v4 as uuidV4 } from "uuid";
import { VehicleService } from "../services/db.vehicle.service";

const MyVehiclePage = () => {
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<MyVehicle>(
    {} as MyVehicle
  );
  const [myVehicleList, setMyVehicleList] = useState<MyVehicle[]>([]);
  const [updateType, setUpdateType] = useState("Add New Vehicle");

  //get my vehicle list

  useEffect(() => {
    const call = async () => {
      setMyVehicleList(
        Object.values(await VehicleService.getUserVehicleList())
      );
    };

    call();
  }, [showAddVehicle]);

  function handleAddNewVehicle() {
    //its new vehicle so add a unique id
    const uniqueId = uuidV4();
    setSelectedVehicle({ ...({} as MyVehicle), vID: uniqueId });
    setShowAddVehicle(!showAddVehicle);
  }

  return (
    <PageLayout>
      <PageHeader title={"My Vehicles"} />

      {showAddVehicle ? (
        <AddMyVehicle
          selectedVehicle={selectedVehicle}
          setShowAddVehicle={setShowAddVehicle}
          updateType={updateType}
        />
      ) : (
        <MyVehicleList
          vehicles={myVehicleList}
          setSelectedVehicle={setSelectedVehicle}
          setShowAddVehicle={setShowAddVehicle}
          setUpdateType={setUpdateType}
        />
      )}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="end"
      >
        <Grid item xs={12} md={12}>
          <Button
            sx={{
              // position: "absolute",
              // bottom: "5rem",
              // right: "5rem",
              padding: "1rem",
              margin: "1rem",
            }}
            variant={"text"}
            color={"secondary"}
            startIcon={showAddVehicle ? <Cancel /> : <AddRounded />}
            size={"large"}
            onClick={() => handleAddNewVehicle()}
          >
            {showAddVehicle ? "Cancel" : "Add New Vehicle"}
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default MyVehiclePage;
