import React, { useMemo } from "react";
import {
  Box,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
} from "@mui/material";

import CSLink from "../../CSLink/CSLink";
import CSLoginButton from "../../CSLoginButton/CSLoginButton";
import IMAGES from "../../../../constants/IMAGES";
import { useAuth } from "../../../../providers/AuthProvider";
import UserService from "../../../../services/user.service";
import { AuthenticationService } from "../../../../services/authentication.service";

interface Props {
  handleClose: () => void;
  currentPath: string;
}

const MenuComponent = (props: Props) => {
  const { handleClose, currentPath } = props;
  const theme = useTheme();
  const { user } = useAuth();
  const loggedIn = Boolean(user);

  const handleLogout = async () => {
    await AuthenticationService.signOut();
    handleClose();
  };

  const links = useMemo(
    () => [
      {
        icon: "home_outlined",
        name: "Home",
        to: "/",
        isPublic: true,
      },
      {
        icon: "book",
        name: "My Bookings",
        to: "/my-bookings",
        isPublic: false,
      },
      {
        icon: "favorite_outlined",
        name: "My Favourites",
        to: "/search#favorites",
        isPublic: false,
      },
      {
        icon: "receipt_long_outlined",
        name: "Billing History",
        to: "/billing-history",
        isPublic: false,
      },
      {
        icon: "local_shipping_outlined",
        name: "My Vehicles",
        to: "/my-vehicles",
        isPublic: false,
      },
      {
        icon: "account_circle_outlined",
        name: "My Profile",
        to: "/my-profile",
        isPublic: false,
      },
      {
        icon: "help_outline",
        name: "Help Centre",
        to: "/help-centre",
        isPublic: true,
      },
    ],
    // TODO: add locale
    []
  );

  return (
    <Box
      role="presentation"
      sx={{
        width: 300,
      }}
      onKeyDown={handleClose}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          background: theme.palette.background.paper,
          padding: "1rem",
        }}
      >
        <img src={IMAGES.APP_LOGO} width={100} height={43.53} alt="CS Logo" />
      </Toolbar>
      <List sx={{ background: theme.palette.background.paper }} dense>
        {links.map(({ icon, name, to, isPublic }) =>
          isPublic || loggedIn ? (
            <ListItem key={to}>
              <CSLink to={to} anchorstyles={{ width: "100%" }}>
                <ListItemButton
                  sx={{
                    background:
                      currentPath === to
                        ? theme.palette.background.default
                        : theme.palette.background.paper,
                  }}
                  onClick={handleClose}
                >
                  <ListItemIcon sx={{ minWidth: 28 }}>
                    <Icon fontSize="small">{icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              </CSLink>
            </ListItem>
          ) : null
        )}
        <Divider sx={{ my: "1rem" }} />
        {loggedIn ? (
          <ListItem>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon sx={{ minWidth: 28 }}>
                <Icon>{"logout"}</Icon>
              </ListItemIcon>
              <ListItemText primary={"Log Out"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem sx={{ pl: "2rem" }}>
            <CSLoginButton />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default MenuComponent;
