import { ChevronLeftRounded } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

interface Props {
  title: string;
}

const PageHeader = (props: Props) => {
  const { title } = props;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const handleBack = () => {
    // router.back();
  };

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: isSm ? "1rem" : "2rem 1rem",
        background: theme.palette.background.default,
      }}
    >
      {/*<IconButton onClick={handleBack}>*/}
      {/*  <ChevronLeftRounded />*/}
      {/*</IconButton>*/}
      <Typography variant="h2">{title}</Typography>
      <IconButton sx={{ visibility: "hidden" }}>
        <ChevronLeftRounded />
      </IconButton>
    </Stack>
  );
};

export default PageHeader;
