import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PageHeader from "../components/core/PageHeader/PageHeader";
import HelpCentreFAQ from "../components/pages/help-centre/HelpCentreFAQ";
import HelpCentreLinks from "../components/pages/help-centre/HelpCentreLinks";
import PageLayout from "../layouts/PageLayout";

const HelpCentrePage = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PageLayout containerSx={{ px: isSm ? "0" : "4rem" }}>
      <PageHeader title={"Help Centre"} />
      <Grid container spacing={3} padding="1rem">
        <Grid item xs={12} md={6}>
          <HelpCentreLinks />
        </Grid>
        <Grid item xs={12} md={6}>
          <HelpCentreFAQ />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default HelpCentrePage;
