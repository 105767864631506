import React, { FormEvent, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CSLink from "../CSLink/CSLink";
import CSSubmitButton from "../CSSubmitButton/CSSubmitButton";
import { useSnackbar } from "notistack";
import IMAGES from "../../../constants/IMAGES";
import { UserLoginForm } from "../../../interfaces";
import BackdropComponent from "../Others/BackDropComponent";
import { Link } from "react-router-dom";
// import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../services/firebaseApp";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { TEXT_AUTH } from "../../../constants/TEXTSTRINGS";

interface Props {
  // switchPage: () => void;
}

const ForgotPasswordComponent = (props: Props) => {
  // const { switchPage } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const [form, setForm] = useState<UserLoginForm>({
    email: "",
    password: "",
  });
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);

  const actionCodeSettings = {
    url: "https://app.chargesingh.com/login",
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setForm({ ...form, [e.target.name]: value });
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const sendEmail = await sendPasswordResetEmail(
      form.email,
      actionCodeSettings
    );
    if (sendEmail) {
      //disable all
    }
  };

  return (
    <Stack
      sx={{
        width: "clamp(275px, 100%)",
        padding: "3rem 2rem",
      }}
      spacing="4rem"
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={IMAGES.APP_LOGO} width={200} height={87.06} alt="CS Logo" />
      </Box>

      <Stack
        alignItems="center"
        component="form"
        spacing={2}
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">{"Forgot Password"}</Typography>

        {sending ? <BackdropComponent /> : null}
        {error ? <p>{TEXT_AUTH.PASSWORD_RESET_FAILED}</p> : null}

        <TextField
          variant="outlined"
          placeholder={"Email"}
          name="email"
          type="email"
          fullWidth
          onChange={handleChange}
          disabled={sending}
        />

        <CSSubmitButton
          type="submit"
          variant="contained"
          fullWidth
          disabled={sending}
          onClick={handleSubmit}
        >
          {"Send reset password link"}
        </CSSubmitButton>
        <CSLink to="/login" underlined={"underlined"}>
          {"Back to Login"}
        </CSLink>
      </Stack>

      <Stack
        direction={isSm ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Typography variant="body1">{"Don't have an account?"}</Typography>
        <Link to={"/register"}>
          <Typography sx={{ textDecoration: "underline", cursor: "pointer" }}>
            {"Create an account"}
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

export default ForgotPasswordComponent;
