import React from "react";
import LoginComponent from "../components/core/Authentication/Login";
import AppLayout from "../layouts/AppLayout";
import AuthPageLayout from "../layouts/AuthPageLayout";

const Login = () => {
  return (
    <AppLayout>
      <AuthPageLayout>
        <LoginComponent />
      </AuthPageLayout>
    </AppLayout>
  );
};

export default Login;
