import { ChevronRightRounded } from "@mui/icons-material";
import {
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { Fragment } from "react";
import SX from "../../../../constants/SX";
import { Notification } from "../../../../interfaces";
import EmptyDataLayout from "../../../../layouts/EmptyDataLayout";

interface Props {
  handleCloseNotifications: () => void;
  notifications: Notification[];
}

const NotificationsComponent = (props: Props) => {
  const { handleCloseNotifications, notifications } = props;
  const theme = useTheme();

  const formatDate = (timestamp: number) => {
    const diffInDays = dayjs().diff(timestamp, "day");
    if (diffInDays >= 1) {
      return dayjs(timestamp).format("DD MMMM YYYY [·] HH:mm");
    }
    dayjs.extend(relativeTime);
    return dayjs(timestamp).fromNow();
  };

  return (
    <Box
      role="presentation"
      sx={{
        maxWidth: 300,
      }}
      onKeyDown={handleCloseNotifications}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: theme.palette.grey[50],
        }}
      >
        <IconButton onClick={handleCloseNotifications}>
          <ChevronRightRounded />
        </IconButton>
        <Typography>Notifications</Typography>
        <IconButton sx={{ visibility: "hidden" }}>
          <ChevronRightRounded />
        </IconButton>
      </Toolbar>
      <List sx={{ background: theme.palette.background.paper }} dense>
        {notifications.length ? (
          notifications.map(
            ({ title, description, icon, createdAt }, index) => (
              <Fragment key={title}>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleCloseNotifications}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        padding: "0.5rem",
                        mr: "1rem",
                        borderRadius: "50%",
                        background: icon.backgroundColor,
                      }}
                    >
                      <Icon sx={{ color: icon.iconColor }}>{icon.name}</Icon>
                    </ListItemIcon>
                    <ListItemText>
                      <Stack>
                        <Typography variant="body2" sx={{ ...SX.ELLIPSIS }}>
                          {description}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {formatDate(createdAt)}
                        </Typography>
                      </Stack>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                {index !== notifications.length - 1 ? (
                  <Divider variant="inset" component="li" />
                ) : null}
              </Fragment>
            )
          )
        ) : (
          <EmptyDataLayout message={"No Notifications"} />
        )}
      </List>
    </Box>
  );
};

export default NotificationsComponent;
