import dayjs from "dayjs";

// TODO: change the correct formula
export const calculateChargeTotal = (
  startAt: number,
  endAt: number,
  power: number,
  pricePerHour: number
) => {
  return (
    (dayjs(startAt).diff(dayjs(endAt), "minutes") / 60) * power * pricePerHour
  );
};
