import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import IMAGES from "../../../../constants/IMAGES";
import IndexPageLayout from "../../../../layouts/IndexPageLayout";
import CSLink from "../../../core/CSLink/CSLink";
import IndexActionButton from "./IndexActionButton/IndexActionButton";

interface Props {
  charging?: boolean;
}

const IndexActions = (props: Props) => {
  const { charging } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <IndexPageLayout>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CSLink to="/search">
            <IndexActionButton
              color="info"
              image={{ url: IMAGES.CHARGING_STATION, width: 26, height: 42 }}
              label={"find a charger"}
            />
          </CSLink>
        </Grid>
        <Grid item xs={6}>
          {!charging && isSm ? (
            <IndexActionButton
              color="success"
              image={{ url: IMAGES.CHARGER, width: 45, height: 44 }}
              label={"charge your car"}
            />
          ) : null}
        </Grid>
      </Grid>
    </IndexPageLayout>
  );
};

export default IndexActions;
