import {
  ExpandMoreRounded,
  MenuRounded,
  NotificationsNoneRounded,
} from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import React, { useRef, useState } from "react";
import IMAGES from "../../../constants/IMAGES";
import { Notification } from "../../../interfaces";
import { useAuth } from "../../../providers/AuthProvider";
import CSLink from "../CSLink/CSLink";
import LinkComponent from "../CSLink/CSLink";
import CSLoginButton from "../CSLoginButton/CSLoginButton";
import MenuComponent from "./Menu/Menu";
import MyAccountPopper from "./MyAccountPopper/MyAccountPopper";
import NotificationsComponent from "./Notifications/Notifications";

interface Props {
  notifications: Notification[];
  currentPath: string;
}

const AppbarComponent = (props: Props) => {
  const { notifications, currentPath } = props;
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("md"));
  const { user } = useAuth();
  const loggedIn = Boolean(user);

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = () => setOpenMenu(true);
  const handleCloseMenu = () => setOpenMenu(false);

  const [openMyAcc, setOpenMyAcc] = useState(false);
  const myAcc = useRef<HTMLSpanElement>(null);

  const [openNotifications, setOpenNotifications] = useState(false);
  const handleOpenNotifications = () => setOpenNotifications(true);
  const handleCloseNotifications = () => setOpenNotifications(false);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        boxShadow: "none",
        backgroundColor: "white",
        zIndex: 9,
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleOpenMenu}
        >
          <MenuRounded />
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
        >
          <MenuComponent
            handleClose={handleCloseMenu}
            currentPath={currentPath}
          />
        </SwipeableDrawer>

        <Box
          flexGrow={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isLg ? "flex-start" : "center",
            alignContent: "center",
            marginTop: "0.3rem",
          }}
        >
          <CSLink to="/">
            <img
              src={IMAGES.APP_LOGO}
              width={150}
              height={65.3}
              alt="CS Logo"
            />
          </CSLink>
        </Box>

        {isLg ? (
          loggedIn ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{ fontWeight: "bold" }}
            >
              <LinkComponent to="/">{"Home"}</LinkComponent>
              <LinkComponent to="/search">{"Find A Charger"}</LinkComponent>
              <LinkComponent to="/help-centre">{"Help Centre"}</LinkComponent>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  variant="body1"
                  ref={myAcc}
                  onClick={() => setOpenMyAcc(!openMyAcc)}
                  onMouseEnter={() => setOpenMyAcc(true)}
                  onMouseLeave={() => setOpenMyAcc(false)}
                  sx={{ fontWeight: "bold", display: "", cursor: "pointer" }}
                >
                  {"My Account"}
                </Typography>
                <ExpandMoreRounded />
                <MyAccountPopper
                  open={openMyAcc}
                  setOpen={setOpenMyAcc}
                  anchorEl={myAcc}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{ fontWeight: "bold" }}
            >
              <LinkComponent to="/search">{"Find A Charger"}</LinkComponent>
              <LinkComponent to="/help">{"Help Centre"}</LinkComponent>
              <CSLoginButton />
            </Stack>
          )
        ) : null}

        {loggedIn ? (
          <>
            <IconButton
              size="large"
              onClick={handleOpenNotifications}
              sx={{ ml: "1rem" }}
            >
              <Badge
                badgeContent={notifications.length}
                color="error"
                variant="dot"
                overlap="circular"
              >
                <NotificationsNoneRounded color="secondary" />
              </Badge>
            </IconButton>
            <SwipeableDrawer
              anchor="right"
              open={openNotifications}
              onOpen={handleOpenNotifications}
              onClose={handleCloseNotifications}
            >
              <NotificationsComponent
                handleCloseNotifications={handleCloseNotifications}
                notifications={notifications}
              />
            </SwipeableDrawer>
          </>
        ) : (
          <IconButton size="large" sx={{ visibility: "hidden" }}>
            <NotificationsNoneRounded color="secondary" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppbarComponent;
