import React from "react";
import AppLayout from "../layouts/AppLayout";
import AuthPageLayout from "../layouts/AuthPageLayout";
import ForgotPasswordComponent from "../components/core/Authentication/ForgotPasswordComponent";

const ForgotPassword = () => {
  return (
    <AppLayout>
      <AuthPageLayout>
        <ForgotPasswordComponent />
      </AuthPageLayout>
    </AppLayout>
  );
};

export default ForgotPassword;
