import { Box, Typography } from "@mui/material";
import React from "react";
import SX from "../constants/SX";

interface Props {
  message?: string;
}
const EmptyDataLayout = (props: Props) => {
  const { message } = props;

  return (
    <Box
      sx={{
        minHeight: 300,
        width: "100%",
        ...SX.CENTER_EVERYTHING,
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {message || "No Data"}
      </Typography>
    </Box>
  );
};

export default EmptyDataLayout;
