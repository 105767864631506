import { Redirect, Route, RouteProps } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebaseApp";
import React from "react";
import BackdropComponent from "../components/core/Others/BackDropComponent";
import PrivateRoute from "./PrivateRoute";
import Index from "../pages";

const PublicRoute = (routeProps: RouteProps) => {
  const [user, loading] = useAuthState(auth);
  //
  if (Boolean(user)) return <Redirect to="/" />;
  // if (user) return <Route {...routeProps} />;
  //
  // if (loading) return <BackdropComponent />;

  return <Route {...routeProps} />;
};

export default PublicRoute;
