import { Stack, SxProps } from "@mui/material";

import React, { Fragment, ReactNode } from "react";
import AppbarComponent from "../components/core/Appbar/Appbar";
import APPBAR_HEIGHT from "../constants/APPBAR_HEIGHT";
import { mockNotifications } from "../utils/mocks";

interface Props {
  children: ReactNode;
  containerSx?: SxProps;
}
const PageLayout = (props: Props) => {
  const { children, containerSx } = props;

  return (
    <Fragment>
      <AppbarComponent notifications={mockNotifications} currentPath={""} />
      <Stack
        sx={{
          alignItems: "center",
          mt: APPBAR_HEIGHT,
          // mb: asPath.includes("/search") ? "" : "3rem",
          ...containerSx,
        }}
      >
        {children}
      </Stack>
    </Fragment>
  );
};

export default PageLayout;
