import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#F8BE15",
      light: "#FFFBEB",
      dark: "#dba404",
      contrastText: "#333333",
    },
    secondary: {
      main: "#333333",
      light: "#333333",
      dark: "#333333",
      contrastText: "#F7F7F9",
    },
    success: {
      main: "#36A852",
      light: "#F0FDF4",
      dark: "#31983f",
      contrastText: "#F7F7F9",
    },
    info: {
      main: "#557EBF",
      light: "#F5F5FF",
      dark: "#3563ab",
      contrastText: "#F7F7F9",
    },
    error: {
      main: "#cb1f27",
      // light: "#FEF2F2",
      dark: "#cb1f27",
    },
    warning: {
      main: "#F8BE15",
      // light: "#FFFBEB",
    },
    common: {
      black: "#333333",
      white: "#F7F7F9",
    },
    background: {
      default: "#F7F7F9",
      paper: "#FFFFFF",
    },
  },

  typography: {
    fontFamily: ['"Open Sans", Helvetica, Arial, Verdana, sans-serif'].join(
      ","
    ),
    h1: {
      fontSize: "2em",
      color: "#333333",
      fontWeight: "bold",
      margin: "1rem 0",
    },
    h2: {
      fontSize: "1.5em",
      color: "#333333",
      fontWeight: "bold",
      margin: "0.8rem 0",
    },
    h3: {
      fontSize: "1.2em",
      color: "#333333",
      fontWeight: "bold",
      margin: "0.8rem 0",
    },
    h4: {
      fontSize: "1em",
      color: "#333333",
      fontWeight: "bold",
      margin: "0.5rem 0",
    },
    h5: {
      fontSize: "1em",
      color: "#333333",
    },
    body1: {
      lineHeight: "1.8em",
    },
  },

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "common.light",
          border: "none",
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
          "&$expanded": {
            margin: "auto",
          },
        },
        expanded: {},
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "centre",
          minHeight: 56,
          "&$expanded": {
            minHeight: 56,
          },
        },
        content: {
          "&$expanded": {
            margin: 0,
          },
        },
        expanded: {},
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "2rem",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: "bold",
          borderRadius: "0.5rem",
          padding: "0.5rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 1px 2px -1px rgba(16, 24, 40, 0.1)",
          filter: "drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          background: "white",
        },
      },
    },
  },
});

export default theme;
