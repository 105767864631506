import { Chip, useTheme } from "@mui/material";
import React from "react";
import CHARGER_AVAILABILITY from "../../../constants/CHARGER_AVAILABILITY";

interface Props {
  availability: string;
}

const AvailabilityChip = (props: Props) => {
  const { availability } = props;
  const theme = useTheme();

  switch (availability) {
    case CHARGER_AVAILABILITY.AVAILABLE:
      return (
        <Chip
          label={"Available"}
          sx={{
            background: theme.palette.success.light,
            color: theme.palette.success.dark,
            fontWeight: "bold",
            height: "22px",
          }}
        />
      );
    case CHARGER_AVAILABILITY.BUSY:
      return (
        <Chip
          label={"Busy"}
          sx={{
            background: theme.palette.error.light,
            color: theme.palette.error.dark,
            fontWeight: "bold",
            height: "22px",
          }}
        />
      );
    case CHARGER_AVAILABILITY.CLOSED:
      return (
        <Chip
          label={"Closed"}
          sx={{
            background: theme.palette.warning.light,
            color: theme.palette.warning.dark,
            fontWeight: "bold",
            height: "22px",
          }}
        />
      );
    case CHARGER_AVAILABILITY.RESERVED:
      return (
        <Chip
          label={"Reserved"}
          sx={{
            background: theme.palette.success.light,
            color: theme.palette.success.dark,
            fontWeight: "bold",
            height: "22px",
          }}
        />
      );
    default:
      return null;
  }
};

export default AvailabilityChip;
