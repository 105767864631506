export const formatNumber = (
  val: number,
  minimumFractionDigits: number = 0
) => {
  return Intl.NumberFormat("en", {
    notation: "compact",
    minimumFractionDigits,
  }).format(val);
};

export const formatCurrency = (val: number) => {
  return Intl.NumberFormat("en", {
    style: "currency",
    currency: "MYR",
  })
    .format(val)
    .replace("MYR", "RM");
};

export const formatShortName = (name: string) => {
  return name.split(" ").slice(0, 2).join("").toUpperCase();
};
