import { EventAvailableRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import React, { useEffect, useMemo, useState } from "react";
import BOOKING_STATUS from "../../../../constants/BOOKING_STATUS";
import { Booking, Charger } from "../../../../interfaces";
import { formatCurrency } from "../../../../utils/formatter";
import { calculateChargeTotal } from "../../../../utils/calculator";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import grey from "@mui/material/colors/grey";
import SX from "../../../../constants/SX";
import SearchDetailsHeader from "./SearchDetailsHeader";
import { MyVehicle } from "../../../../interfaces/Vehicle";
import { VehicleService } from "../../../../services/db.vehicle.service";

interface Props {
  charger: Charger;
}

function handleRadioChange() {}

const ChargerDetailsComponent = (props: Props) => {
  const { charger } = props;
  const theme = useTheme();

  const [bookingForm, setBookingForm] = useState({
    date: dayjs(),
    bookingTimeRange: { startAt: dayjs(), endAt: dayjs().add(1, "hour") },
  });
  const [myVehicleList, setMyVehicleList] = useState<MyVehicle[]>([]);
  const [selectedVehicleID, setSelectedVehicleID] = useState("");
  const handleDateChange = (
    newValue: dayjs.Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => {
    if (!newValue) return;
    setBookingForm({ ...bookingForm, date: newValue });
  };
  const handleTimeStartChange = (
    newValue: dayjs.Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => {
    if (!newValue) return;
    setBookingForm({
      ...bookingForm,
      bookingTimeRange: { ...bookingForm.bookingTimeRange, startAt: newValue },
    });
  };
  const handleTimeEndChange = (
    newValue: dayjs.Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => {
    if (!newValue) return;
    setBookingForm({
      ...bookingForm,
      bookingTimeRange: { ...bookingForm.bookingTimeRange, endAt: newValue },
    });
  };

  // TODO: call API to book, then render booking details depending on status
  const [bookingStatus, setBookingStatus] = useState(-999);

  const booking = useMemo<Booking>(() => {
    if (bookingStatus !== BOOKING_STATUS.SUCCESS) return {} as Booking;

    const startAt = dayjs(
      `${bookingForm.date.format(
        "DD/MM/YYYY"
      )} ${bookingForm.bookingTimeRange.startAt.format("HH:mm")}`
    ).valueOf();
    const endAt = dayjs(
      `${bookingForm.date.format(
        "DD/MM/YYYY"
      )} ${bookingForm.bookingTimeRange.endAt.format("HH:mm")}`
    ).valueOf();
    return {
      id: "12345",
      charger,
      bookTimeRange: {
        startAt,
        endAt,
      },
      status: BOOKING_STATUS.SUCCESS,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingStatus]);

  const handleBook = () => {
    const successOrError = Math.floor(Math.random() * 2 - 1);
    setBookingStatus(successOrError);
    if (successOrError === BOOKING_STATUS.SUCCESS) {
      // router.push("#success");
    }
  };

  useEffect(() => setBookingStatus(-999), [charger]);
  useEffect(() => {
    const call = async () => {
      setMyVehicleList(
        Object.values(await VehicleService.getUserVehicleList())
      );
    };

    call();
  }, []);
  console.log("selectedVehicleID", selectedVehicleID);

  const estimatedTotal = useMemo(() => {
    return calculateChargeTotal(
      bookingForm.bookingTimeRange.endAt.valueOf(),
      bookingForm.bookingTimeRange.startAt.valueOf(),
      charger.cPower,
      charger.cPrice
    );
  }, [bookingForm, charger]);

  return (
    <Grid container justifyContent={"center"} spacing={2}>
      <Box
        sx={{
          ...SX.CENTER_EVERYTHING,
          width: "100%",
          background: theme.palette.primary.main,
          padding: "2rem 1rem",
        }}
      >
        <SearchDetailsHeader charger={charger} />
      </Box>
      <Grid
        item
        xs={10}
        md={8}
        sx={{
          marginTop: "1.5rem",
          marginBottom: "2rem",
        }}
      >
        <Card
          sx={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
          }}
        >
          <Carousel
            centerMode={false}
            emulateTouch={true}
            showArrows={true}
            showStatus={false}
            showIndicators={true}
            dynamicHeight={false}
            showThumbs={false}
          >
            <div>
              <img src={charger.cImage} />
            </div>
            <div>
              <img src={charger.cImage} />
            </div>
            <div>
              <img src={charger.cImage} />
            </div>
          </Carousel>
        </Card>

        <Card
          sx={{
            marginTop: "1rem",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ padding: "1rem 2rem" }}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={6}>
              <Typography variant={"h3"}>
                {"Select Booking Date/Time"}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ padding: "1rem 2rem" }}
                justifyContent={"center"}
              >
                <Grid item xs={12} md={12}>
                  <DatePicker
                    value={bookingForm.date}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField label={"Date"} fullWidth {...params} />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TimePicker
                    value={bookingForm.bookingTimeRange.startAt}
                    onChange={handleTimeStartChange}
                    renderInput={(params) => (
                      <TextField label={"Start Time"} fullWidth {...params} />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TimePicker
                    value={bookingForm.bookingTimeRange.endAt}
                    onChange={handleTimeEndChange}
                    renderInput={(params) => (
                      <TextField label={"End Time"} fullWidth {...params} />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"h3"}>{"Select Your Vehicle"}</Typography>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={""}
                  onChange={handleRadioChange}
                >
                  {myVehicleList.map((item) => (
                    <FormControlLabel
                      value={item.vID}
                      control={<Radio />}
                      label={
                        <p>
                          {item.vTitle}
                          <small> [{item.vBrand}] </small>
                        </p>
                      }
                      checked={item.vID === selectedVehicleID}
                      onChange={(e) => setSelectedVehicleID(item.vID)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ padding: "1rem 2rem" }}
            justifyContent={"center"}
          >
            <Grid item xs={12}>
              <Typography variant={"h3"}>{"Billing Details"}</Typography>
              <Stack
                spacing={4}
                sx={{ padding: "2rem 2rem 3rem", background: grey[50] }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5">{"Estimated Total"}</Typography>
                  <Typography variant="h5" fontWeight="bold">
                    {formatCurrency(estimatedTotal)}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<EventAvailableRounded />}
                  onClick={handleBook}
                >
                  {"Book Charger"}
                </Button>

                <Typography variant="caption">
                  While booking charger, you are agreed to our terms and
                  conditions
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          {/*{bookingStatus === -999 ? (*/}
          {/* */}
          {/*) : bookingStatus === BOOKING_STATUS.SUCCESS ? (*/}
          {/*  <SearchBookingSuccess booking={booking} />*/}
          {/*) : bookingStatus === BOOKING_STATUS.ERROR ? (*/}
          {/*  <SearchBookingError*/}
          {/*    back={() => setBookingStatus(-999)}*/}
          {/*    message="This error is randomised to demonstrate that error message does work."*/}
          {/*  />*/}
          {/*) : null}*/}
        </Card>
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent={"center"}
          sx={{ marginTop: "1rem" }}
        >
          <Button size={"small"} variant={"text"} color={"inherit"}>
            Terms
          </Button>
          <Button size={"small"} variant={"text"} color={"inherit"}>
            Help
          </Button>
          <Button size={"small"} variant={"text"} color={"inherit"}>
            Contact us
          </Button>
          <Button size={"small"} variant={"text"} color={"inherit"}>
            Report Issue
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ChargerDetailsComponent;
