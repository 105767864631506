const SX = {
  CENTER_EVERYTHING: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ELLIPSIS: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    // display: "-webkit-box",
    // "-webkit-line-clamp": "2",
    // "-webkit-box-orient": "vertical",
  },
};

export default SX;
