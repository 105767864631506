import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { mockUser } from "../../../utils/mocks";
import * as yup from "yup";
import SX from "../../../constants/SX";
import {
  useAuthState,
  useSendEmailVerification,
} from "react-firebase-hooks/auth";
import { auth } from "../../../services/firebaseApp";

const MyProfileDetails = () => {
  const theme = useTheme();
  const [user, loading] = useAuthState(auth);
  const [sendEmailVerification, sending, error] =
    useSendEmailVerification(auth);

  const [editField, setEditField] = useState(false);

  const { values, errors, touched, handleChange } = useFormik({
    validationSchema: yup.object({
      fullName: yup.string().required(),
      email: yup.string().required().email(),
      phoneNo: yup.number().required(),
    }),
    initialValues: { ...user },
    onSubmit: () => {
      console.log(values);
    },
    validateOnChange: true,
  });

  async function handleEmailResend() {
    await sendEmailVerification();
    if (sending) {
      enqueueSnackbar("Sending Email", { variant: "success" });
    }
    if (error) {
      enqueueSnackbar("Error sending email", { variant: "error" });
    }
  }

  return (
    <Paper
      sx={{
        padding: "2rem 1rem",
        ...SX.CENTER_EVERYTHING,
        marginTop: "1rem",
      }}
    >
      <Grid
        container
        spacing={4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={8}>
          <Typography variant={"body1"} color={"secondary"}>
            <b>Welcome, {values.email}</b>
          </Typography>
        </Grid>
        {!user?.emailVerified ? (
          <Grid item xs={8}>
            <Alert severity="warning">
              <AlertTitle>Email address is not verified</AlertTitle>
              Please verify your email before proceeding
            </Alert>
            <Button variant={"text"} onClick={handleEmailResend}>
              Resend Verification email.
            </Button>
          </Grid>
        ) : null}

        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            variant="outlined"
            name="fullName"
            label={"Full Name"}
            placeholder={mockUser.fullName}
            value={user?.displayName}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            variant="outlined"
            name="phoneNo"
            label={"Phone Number"}
            placeholder={mockUser.phoneNo}
            value={user?.phoneNumber}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container justifyContent={"center"} spacing={4}>
            <Grid item xs={6}>
              <Button type="submit" variant="contained" fullWidth>
                {"Update"}
              </Button>
              <Button
                variant="text"
                color={"error"}
                fullWidth
                onClick={() => auth.signOut()}
              >
                {"Logout"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyProfileDetails;

function enqueueSnackbar(message: string, arg1: { variant: string }) {
  throw new Error("Function not implemented.");
}
