import {
  BoltRounded,
  DirectionsRounded,
  PaidOutlined,
} from "@mui/icons-material";
import { Button, Card, Grid, Stack, Typography, useTheme } from "@mui/material";

import React, { useEffect, useState } from "react";
import SX from "../../../../constants/SX";
import { Charger } from "../../../../interfaces";
import { formatCurrency, formatNumber } from "../../../../utils/formatter";
import AvailabilityChip from "../../../core/AvailabilityChip/AvailabilityChip";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import grey from "@mui/material/colors/grey";
import { nativeLocation } from "../../../../services/location.service";
import { green } from "@mui/material/colors";

interface Props {
  charger: Charger;
  currentLocation: GeolocationPosition;
}

const SearchChargerListTile = (props: Props) => {
  const {charger, currentLocation} = props;
  const theme = useTheme();
  const location = useHistory();
  const [distance, setDistance] = useState(0);

  const handleClick = () => {
    location.push(`/chargers/${charger.cID}`);
  };

  useEffect(() => {
    const call = async () => {
      if (!currentLocation.coords) return;

      const distanceTemp = nativeLocation(
        charger.cLat,
        charger.cLong,
        currentLocation
      );
      console.log(distanceTemp);
      setDistance(distanceTemp);
    };

    call();
  }, [currentLocation]);

  return (
    <Card>
      <Box
        sx={{
          padding: "2rem",
          cursor: "pointer",
          ":hover": {
            background: grey[50],
          },
        }}
        onClick={handleClick}
      >
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {charger.cTitle}
              </Typography>

              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{...SX.ELLIPSIS}}
              >
                {charger.cAddress}
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <BoltRounded color="primary"/>
                  <Typography variant="caption" color="textSecondary">
                    {`${formatNumber(charger.cPower, 1)} kW`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <PaidOutlined color="primary"/>
                  <Typography variant="caption" color="textSecondary">
                    {`${formatCurrency(charger.cPrice)} / kWh`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <AvailabilityChip availability={charger.cAvailability}/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack
              height="100%"
              justifyContent="space-between"
              alignItems="center"
              direction={"row"}
            >
              <Typography variant="caption" color={green[500]}>
                {distance > 0 ? `${String(distance)} KM away.` : ""}
              </Typography>
              <Button
                component="a"
                target="_blank"
                color="info"
                size="small"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "0.8rem",
                  textTransform: "capitalize",
                  padding: "1rem",
                  borderRadius: "1rem",
                  "& .MuiButton-startIcon": {
                    margin: 0,
                  },
                }}
                startIcon={<DirectionsRounded fontSize="large" color="info"/>}
              >
                {"Directions"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default SearchChargerListTile;
