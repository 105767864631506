import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CSLink from "../CSLink/CSLink";
import CSSubmitButton from "../CSSubmitButton/CSSubmitButton";
import { mockFetching } from "../../../utils/mocks";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import IMAGES from "../../../constants/IMAGES";
import { useAuth } from "../../../providers/AuthProvider";
import { AuthenticationService } from "../../../services/authentication.service";
import { Redirect } from "react-router";
import { UserLoginForm } from "../../../interfaces";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../../services/firebaseApp";
import BackdropComponent from "../Others/BackDropComponent";
import { Link } from "react-router-dom";

interface Props {
  // switchPage: () => void;
}

const LoginComponent = (props: Props) => {
  // const { switchPage } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const [form, setForm] = useState<UserLoginForm>({
    email: "",
    password: "",
  });
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const loginMutation = useMutation(mockFetching, {
    onError: () => {
      enqueueSnackbar("Failed to login", { variant: "error" });
    },
    onSuccess: () => {
      enqueueSnackbar("Authentication successfully!", { variant: "success" });
    },
  });
  const handleChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setForm({ ...form, [e.target.name]: value });
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await signInWithEmailAndPassword(form.email, form.password);
  };

  return (
    <Stack
      sx={{
        width: "clamp(275px, 100%)",
        padding: "3rem 2rem",
      }}
      spacing="4rem"
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={IMAGES.APP_LOGO} width={200} height={87.06} alt="CS Logo" />
      </Box>

      <Stack
        alignItems="center"
        component="form"
        spacing={2}
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">{"Login"}</Typography>

        {loading ? <BackdropComponent /> : null}

        <TextField
          variant="outlined"
          placeholder={"Email"}
          name="email"
          type="email"
          fullWidth
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          placeholder={"Password"}
          name="password"
          type="password"
          fullWidth
          onChange={handleChange}
        />
        <CSSubmitButton
          type="submit"
          variant="contained"
          fullWidth
          disabled={loginMutation.isLoading}
          onClick={handleSubmit}
        >
          {"Log In"}
        </CSSubmitButton>
        <CSLink to="/forgot-password" underlined={"underlined"}>
          {"Forgot Password"}
        </CSLink>
      </Stack>

      <Stack
        direction={isSm ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Typography variant="body1">{"Don't have an account?"}</Typography>
        <Link to={"/register"}>
          <Typography sx={{ textDecoration: "underline", cursor: "pointer" }}>
            {"Create an account"}
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

export default LoginComponent;
