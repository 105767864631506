import { AddRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { mockFAQs } from "../../../utils/mocks";

const HelpCentreFAQ = () => {
  const [expanded, setExpanded] = useState("");

  const handleExpand =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : "");
    };

  return (
    <Stack spacing={2}>
      <Typography variant="h3">{"Frequently Asked Questions"}</Typography>
      {mockFAQs.map((faq) => (
        <Accordion
          square
          disableGutters
          expanded={expanded === faq.id}
          onChange={handleExpand(faq.id)}
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          key={faq.id}
        >
          <AccordionSummary expandIcon={<AddRounded />}>
            <Typography variant="h4">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="textSecondary">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

export default HelpCentreFAQ;
