import { MyLocation } from "@mui/icons-material";
import { Button, Grid, Typography, useTheme } from "@mui/material";

import React from "react";
import SX from "../../../../constants/SX";
import { Charger } from "../../../../interfaces";
import AvailabilityChip from "../../../core/AvailabilityChip/AvailabilityChip";
import { useHistory } from "react-router-dom";

interface Props {
  charger: Charger;
}

const SearchDetailsHeader = (props: Props) => {
  const { charger } = props;
  const theme = useTheme();
  const location = useHistory();

  const handleClick = () => {
    location.push(`/chargers/${charger.cID}`);
  };

  return (
    <Grid container spacing={4} justifyContent={"center"}>
      <Grid item xs={10} md={8}>
        <Typography variant="h3" fontWeight="bold" sx={{ ...SX.ELLIPSIS }}>
          {`${charger.cTitle}`}
          <AvailabilityChip availability={charger.cAvailability} />
        </Typography>
        <Typography variant="body1" color="inherit" sx={{ ...SX.ELLIPSIS }}>
          {charger.cDetails}
        </Typography>
        <Typography variant="body1" color="inherit" sx={{ ...SX.ELLIPSIS }}>
          {charger.cAddress}
        </Typography>
        <br />
        <Button
          variant={"outlined"}
          component="a"
          target="_blank"
          color="inherit"
          size="medium"
          endIcon={<MyLocation fontSize="large" color="inherit" />}
        >
          {"Directions"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchDetailsHeader;
