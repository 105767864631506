import { BoltRounded } from "@mui/icons-material";
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";

import React from "react";
import SX from "../../../../constants/SX";
import { mockVehicles } from "../../../../utils/mocks";

interface Props {}

const IndexHeader = (props: Props) => {
  const {} = props;
  const theme = useTheme();
  const vehicle = mockVehicles[0];

  return (
    <Box
      sx={{
        ...SX.CENTER_EVERYTHING,
        width: "100%",
        height: "auto",
        background: theme.palette.primary.main,
        padding: "2rem 1rem",
      }}
    >
      <Stack direction="row" justifyContent="space-between" width={300}>
        <Stack>
          <Typography variant="h3">{vehicle.vBrand}</Typography>
          <Chip
            icon={
              <BoltRounded
                sx={{ "&&": { color: theme.palette.primary.main } }}
              />
            }
            color="success"
            label={"Charging"}
            sx={{ fontWeight: "bold" }}
          />
        </Stack>
        <Box
          sx={{
            ...SX.CENTER_EVERYTHING,
            position: "relative",
          }}
        >
          <img
            src={vehicle.vImage}
            alt={vehicle.vBrand}
            style={{ zIndex: 1 }}
          />
          <Box
            sx={{
              borderRadius: "50%",
              background: theme.palette.background.paper,
              opacity: 0.5,
              width: "90px",
              height: "90px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default IndexHeader;
