import { Button, styled } from "@mui/material";

const CSSubmitButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: ".5rem",
  fontWeight: 700,
  fontSize: "1.1rem",
  boxShadow: "none",
  "&:hover": {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

export default CSSubmitButton;
