import {
  Fade,
  Icon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";

import React, { RefObject, useMemo } from "react";
import { useAuth } from "../../../../providers/AuthProvider";
import CSLink from "../../CSLink/CSLink";
import { AuthenticationService } from "../../../../services/authentication.service";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  anchorEl: RefObject<HTMLSpanElement>;
}

const MyAccountPopper = (props: Props) => {
  const { open, setOpen, anchorEl } = props;
  const theme = useTheme();

  const links = useMemo(
    () => [
      {
        icon: "book",
        name: "My Bookings",
        to: "/my-bookings",
      },
      {
        icon: "favorite_outlined",
        name: "My Favourites",
        to: "/search#favorites",
      },
      {
        icon: "receipt_long_outlined",
        name: "Billing History",
        to: "/billing-history",
      },
      {
        icon: "local_shipping_outlined",
        name: "My Vehicles",
        to: "/my-vehicles",
      },
      {
        icon: "account_circle_outlined",
        name: "My Profile",
        to: "/my-profile",
      },
    ],
    // TODO: add locale
    []
  );

  return (
    <Popper
      style={{ zIndex: 10 }}
      open={open}
      anchorEl={anchorEl.current}
      role="menu"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            sx={{ borderRadius: "1rem" }}
          >
            <MenuList sx={{ minWidth: 250 }} dense>
              {links.map(({ icon, name, to }) => (
                <ListItem key={to}>
                  <CSLink to={to} anchorstyles={{ width: "100%" }}>
                    <ListItemButton
                      sx={{
                        background:
                          to === to
                            ? theme.palette.background.paper
                            : theme.palette.background.paper,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 28 }}>
                        <Icon fontSize="small">{icon}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={name} />
                    </ListItemButton>
                  </CSLink>
                </ListItem>
              ))}
              <ListItem>
                <ListItemButton onClick={AuthenticationService.signOut}>
                  <ListItemIcon sx={{ minWidth: 28 }}>
                    <Icon>{"logout"}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={"Log Out"} />
                </ListItemButton>
              </ListItem>
            </MenuList>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default MyAccountPopper;
